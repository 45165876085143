import { CircleStackIcon } from "@heroicons/react/24/outline"
import { get, isNil } from "lodash"
import UserNotificationModel from "../../../../Core/Models/Notifications/UserNotificationModel"
import BaseStrategyUserNotification from "../BaseStrategyUserNotification"
import { ResponseMessageData } from "../StrategyUserNotification"
import { FetcherProject } from "@/Project/Fetcher"

class UserNotificationBackupDownloadedDatabase extends BaseStrategyUserNotification {
  protected getBadge() {
    return {
      icon: CircleStackIcon,
      title: "Backup Database",
    }
  }

  public getMessageDataSuccess(
    notification: UserNotificationModel,
  ): ResponseMessageData {
    const name = notification.getProjectName()

    const message = `The backup of [${name}] is ready. Click here to download it`

    return {
      message: message,
      badge: this.getBadge(),
      icons: this.getIcons(notification),
      onClick: async () => {
        const { data, code } =
          await FetcherProject.Backup.getBackupDownloadLinkDatabase({
            projectId: get(notification, "entities.project.id", 0),
            backupId: get(notification, "entities.backup.id", 0),
          })

        if (!isNil(data) && !isNil(data.download_link) && code === "success") {
          window.open(data.download_link, "_blank")
          return
        }
      },
    }
  }

  public getMessageDataError(notification: UserNotificationModel) {
    return {
      message: `We couldn't build your backup. Please contact support.`,
      badge: this.getBadge(),
      icons: this.getIcons(notification),
      link: "/support",
    }
  }
}

export default UserNotificationBackupDownloadedDatabase
