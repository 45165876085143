import { morphism } from "morphism"
import UserNotificationModel, {
  SchemaUserNotificationModel,
} from "../../Core/Models/Notifications/UserNotificationModel"

function MorphUserNotification(useSWRNext) {
  return (key, fetcher, config) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const swr = useSWRNext(key, fetcher, config)
    return {
      ...swr,
      data: morphism(
        SchemaUserNotificationModel,
        swr.data,
        UserNotificationModel,
      ),
    }
  }
}

export default MorphUserNotification
