import UserNotificationModel from "../../../../Core/Models/Notifications/UserNotificationModel"
import BaseStrategyUserNotification from "../BaseStrategyUserNotification"
import { ResponseMessageData } from "../StrategyUserNotification"
import { System } from "@design-system/index"

class UserNotificationBulkSynchroniseProjects extends BaseStrategyUserNotification {
  protected getBadge() {
    return {
      icon: System.Svg.Refresh,
      title: "Re-sync",
    }
  }

  public getMessageDataSuccess(
    notification: UserNotificationModel,
  ): ResponseMessageData {
    const { fails } = this.getTotalTasks(notification)

    let message = ""
    if (fails === 0) {
      message = `Successfully Completed. 👍`
    } else {
      message = `Completed. Your attention might be needed.`
    }

    return {
      message: message,
      badge: this.getBadge(),
      icons: this.getIcons(notification),
    }
  }

  public getMessageDataError(
    notification: UserNotificationModel,
  ): ResponseMessageData {
    return {
      message: `Something wrong happened during the data re-sync. Please contact support.`,
      badge: this.getBadge(),
      icons: this.getIcons(notification),
    }
  }
}

export default UserNotificationBulkSynchroniseProjects
