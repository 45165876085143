import { differenceInDays } from "date-fns"

export const getDiffDayCreatedAtUser = ({
  userCreatedAt,
}: {
  userCreatedAt: string
}) => {
  const now = new Date()

  let diffDayCreatedAt = 0
  try {
    //@ts-ignore
    diffDayCreatedAt = differenceInDays(now, new Date(userCreatedAt))
  } catch (error) {}

  return diffDayCreatedAt
}
