import { User } from "@/Types/User/User"
import { useUserCanListLabels } from "@components/Profile/hooks/capabilities/Label/useUserCanListLabels"
import { useUserCanListMaintenanceCustomers } from "@components/Profile/hooks/capabilities/MaintenanceCustomer/useUserCanListMaintenanceCustomers"
import { useUserCanListMaintenanceReport } from "@components/Profile/hooks/capabilities/MaintenanceReport/useUserCanListMaintenanceReport"
import { useUserCanListNotifications } from "@components/Profile/hooks/capabilities/Notification/useUserCanListNotications"
import { useUserCanManageCustomEmailDomains } from "@components/Profile/hooks/capabilities/Profile/useUserCanManageCustomEmailDomains"
import { useUserCanManageWhiteLabels } from "@components/Profile/hooks/capabilities/Profile/useUserCanManageWhiteLabels"
import { useUserCanListTeamMembers } from "@components/Profile/hooks/capabilities/TeamMember/useUserCanListTeamMembers"
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from "@design-system/Shadcn/ui/collapsible"
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@design-system/Shadcn/ui/dropdown-menu"
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@design-system/Shadcn/ui/tooltip"
import { System } from "@design-system/index"
import { isCurrentTrial } from "@helpers/user/isCurrentTrial"
import useUser from "@hooks/user/useUser"
import useUserIsPremium from "@hooks/user/useUserIsPremium"
import { WorkspaceMenu } from "@layouts/Workspace/WorkspaceMenu"

import classNames from "classnames"
import { isNil } from "lodash"
import { useRouter } from "next/router"
import { useEffect, useState } from "react"

const MENU = [
  {
    path: "/projects",
    active_route: [
      "/projects",
      "/projects/[idProject]",
      "/projects/[idProject]/maintenance-reports",
      "/projects/[idProject]/issues",
      "/projects/[idProject]/updates",
      "/projects/[idProject]/updates/themes",
      "/projects/[idProject]/updates/core",
      "/projects/[idProject]/backups",
      "/projects/[idProject]/backups/v4",
      "/projects/[idProject]/backups/settings",
      "/projects/[idProject]/backups/scheduling",
      "/projects/[idProject]/backups/restoration",
      "/projects/[idProject]/security",
      "/projects/[idProject]/settings",
      "/projects/[idProject]/danger-zone",
    ],
  },
  {
    path: `/reports`,
    active_route: [
      `/reports`,
      `/reports/create`,
      "/reports/[templateId]",
      "/reports/[templateId]/edit-content",
      "/reports/[templateId]/edit-email",
      "/reports/[templateId]/edit-schedule",
      "/reports/[templateId]/sites-selector",
      "/reports/[templateId]/bulk-edit-projects",
    ],
  },
]

export const LeftMenu = () => {
  const {
    mainUser: user,
    isAdminLogInAs,
    isMainAccount,
  } = useUser<User>({
    options: {
      withModel: true,
    },
  })

  const canManageCustomEmailDomains = useUserCanManageCustomEmailDomains()
  const canManageWhiteLabels = useUserCanManageWhiteLabels()
  const canListMaintenanceCustomers = useUserCanListMaintenanceCustomers()
  const canListLabels = useUserCanListLabels()
  const canListNotifications = useUserCanListNotifications()
  const canListTeamMembers = useUserCanListTeamMembers()
  const canlListMaintenanceReport = useUserCanListMaintenanceReport()

  const [openBulkManagement, setOpenBulkManagement] = useState(false)

  useEffect(() => {
    if (!router.asPath.includes("/bulk-actions")) {
      return
    }
    setOpenBulkManagement(true)
  }, [])

  const isTrial = isCurrentTrial({
    userCreatedAt: String(user.created_at),
  })

  const router = useRouter()
  const currentMenu = MENU.find((menu) => {
    return menu.active_route.includes(router.pathname)
  })

  const isPremiumUser = useUserIsPremium()

  return (
    <>
      <div
        className={classNames(
          {
            "top-0": !isTrial && isNil(isAdminLogInAs),
            "top-14": isTrial && isNil(isAdminLogInAs),
            "top-28": isTrial && !isNil(isAdminLogInAs),
          },
          "fixed left-0 top-0 h-screen w-full min-w-[176px] max-w-[240px] bg-slate-50 p-4",
        )}
        style={{
          zIndex: 1501,
        }}
      >
        <div className="flex items-center gap-2">
          <div className="flex-1">
            <WorkspaceMenu />
          </div>
          {(isPremiumUser || !isMainAccount) && (
            <div className="ml-auto flex cursor-pointer items-center rounded-full p-1 transition hover:bg-slate-100">
              <DropdownMenu>
                <DropdownMenuTrigger>
                  <TooltipProvider delayDuration={0}>
                    <Tooltip>
                      <TooltipTrigger
                        className="animate border-b border-b-transparent text-sm group-hover:border-dashed group-hover:border-b-slate-400"
                        asChild
                      >
                        <div className="items-cnter flex">
                          <System.Svg.Settings className="h-5 w-5" />
                        </div>
                      </TooltipTrigger>
                      <TooltipContent className="bg-slate-700 text-slate-50">
                        <p>Configure your workspace</p>
                      </TooltipContent>
                    </Tooltip>
                  </TooltipProvider>
                </DropdownMenuTrigger>
                <DropdownMenuContent
                  align="start"
                  className="w-64"
                  style={{
                    zIndex: 1502,
                  }}
                >
                  <DropdownMenuItem>
                    <System.Link
                      naked
                      href="/workspace/edit"
                      className="flex w-full items-center gap-2"
                    >
                      <System.Svg.Settings className="h-4 w-4 opacity-50" />
                      Workspace Settings
                    </System.Link>
                  </DropdownMenuItem>
                  <DropdownMenuItem>
                    <System.Link
                      naked
                      href="/profile/integrations"
                      className="flex w-full items-center gap-2"
                    >
                      <System.Svg.Puzzle className="h-4 w-4 opacity-50" />
                      Integrations
                    </System.Link>
                  </DropdownMenuItem>
                  {canManageWhiteLabels && (
                    <DropdownMenuItem>
                      <System.Link
                        naked
                        href="/profile/white-label"
                        className="flex w-full items-center gap-2"
                      >
                        <System.Svg.WhiteLabel className="h-4 w-4 opacity-50" />
                        White Label
                      </System.Link>
                    </DropdownMenuItem>
                  )}
                  {canManageCustomEmailDomains && (
                    <DropdownMenuItem>
                      <System.Link
                        naked
                        href="/profile/white-label/custom-email-domain"
                        className="flex w-full items-center gap-2"
                      >
                        <System.Svg.Email className="h-4 w-4 opacity-50" />
                        Custom Sending Domains
                      </System.Link>
                    </DropdownMenuItem>
                  )}
                </DropdownMenuContent>
              </DropdownMenu>
            </div>
          )}
        </div>
        {(isPremiumUser || !isMainAccount) && (
          <nav className="mt-8 space-y-1">
            <div
              className={classNames(
                {
                  "before:border-l-transparent":
                    isNil(currentMenu) || currentMenu.path !== "/projects",
                  "before:border-l-indigo-500":
                    !isNil(currentMenu) && currentMenu.path === "/projects",
                },
                "flex rounded before:my-1.5 before:border-l-2 before:pl-2",
              )}
            >
              <System.Link
                href="/projects"
                className="flex flex-1 items-center rounded-sm p-1 text-sm font-medium transition hover:bg-indigo-50"
              >
                <System.Svg.Site className="mr-2 h-4 w-4" />
                Sites
              </System.Link>
            </div>
            <div className="flex rounded before:my-1.5 before:border-l-2 before:border-transparent before:pl-2">
              <Collapsible className="w-full" open={openBulkManagement}>
                <CollapsibleTrigger
                  className="flex w-full items-center rounded-sm p-1 text-sm font-medium transition hover:bg-slate-100"
                  onClick={() => {
                    setOpenBulkManagement(!openBulkManagement)
                  }}
                >
                  <System.Svg.BulkManagement className="mr-2 h-4 w-4" />
                  Bulk Management
                  <System.Svg.ChevronDown className="ml-auto h-4 w-4" />
                </CollapsibleTrigger>
                <CollapsibleContent className="ml-2.5">
                  <div
                    className={classNames(
                      {
                        "before:border-l-transparent":
                          router.asPath !== "/bulk-actions",
                        "before:border-l-indigo-500":
                          router.asPath === "/bulk-actions",
                      },
                      "flex rounded before:my-1.5 before:border-l-2 before:pl-2",
                    )}
                  >
                    <System.Link
                      href="/bulk-actions"
                      className="flex flex-1 items-center rounded-sm p-1 text-sm font-medium transition hover:bg-indigo-50"
                    >
                      <System.Svg.Plugin className="mr-2 h-4 w-4" />
                      Plugins
                    </System.Link>
                  </div>
                  <div
                    className={classNames(
                      {
                        "before:border-l-transparent":
                          router.asPath !== "/bulk-actions/themes",
                        "before:border-l-indigo-500":
                          router.asPath === "/bulk-actions/themes",
                      },
                      "flex rounded before:my-1.5 before:border-l-2 before:pl-2",
                    )}
                  >
                    <System.Link
                      href="/bulk-actions/themes"
                      className="flex flex-1 items-center rounded-sm p-1 text-sm font-medium transition hover:bg-indigo-50"
                    >
                      <System.Svg.Theme className="mr-2 h-4 w-4" />
                      Themes
                    </System.Link>
                  </div>
                  <div
                    className={classNames(
                      {
                        "before:border-l-transparent":
                          router.asPath !== "/bulk-actions/core",
                        "before:border-l-indigo-500":
                          router.asPath === "/bulk-actions/core",
                      },
                      "flex rounded before:my-1.5 before:border-l-2 before:pl-2",
                    )}
                  >
                    <System.Link
                      href="/bulk-actions/core"
                      className="flex flex-1 items-center rounded-sm p-1 text-sm font-medium transition hover:bg-indigo-50"
                    >
                      <System.Svg.WordPress className="mr-2 h-4 w-4" />
                      Core
                    </System.Link>
                  </div>
                  <div
                    className={classNames(
                      {
                        "before:border-l-transparent":
                          router.asPath !==
                          "/bulk-actions/database-optimization",
                        "before:border-l-indigo-500":
                          router.asPath ===
                          "/bulk-actions/database-optimization",
                      },
                      "flex rounded before:my-1.5 before:border-l-2 before:pl-2",
                    )}
                  >
                    <System.Link
                      href="/bulk-actions/database-optimization"
                      className="flex flex-1 items-center rounded-sm p-1 text-sm font-medium transition hover:bg-indigo-50"
                    >
                      <System.Svg.Database className="mr-2 h-4 w-4" />
                      Database
                    </System.Link>
                  </div>
                </CollapsibleContent>
              </Collapsible>
            </div>
            {canListNotifications && (
              <div
                className={classNames(
                  {
                    "before:border-l-transparent":
                      router.asPath !== "/notifications",
                    "before:border-l-indigo-500":
                      router.asPath === "/notifications",
                  },
                  "flex rounded before:my-1.5 before:border-l-2 before:pl-2",
                )}
              >
                <System.Link
                  href="/notifications"
                  className="flex flex-1 items-center rounded-sm p-1 text-sm font-medium transition hover:bg-slate-100"
                >
                  <System.Svg.Alert className="mr-2 h-4 w-4" />
                  Alerting
                </System.Link>
              </div>
            )}
            {canlListMaintenanceReport && (
              <div
                className={classNames(
                  {
                    "before:border-l-transparent":
                      isNil(currentMenu) || currentMenu.path !== "/reports",
                    "before:border-l-indigo-500":
                      !isNil(currentMenu) && currentMenu.path === "/reports",
                  },
                  "flex rounded before:my-1.5 before:border-l-2 before:pl-2",
                )}
              >
                <System.Link
                  href="/reports"
                  className="flex flex-1 items-center rounded-sm p-1 text-sm font-medium transition hover:bg-slate-100"
                >
                  <System.Svg.Report className="mr-2 h-4 w-4" />
                  Reports
                </System.Link>
              </div>
            )}
            {canListLabels && (
              <div
                className={classNames(
                  {
                    "before:border-l-transparent": router.asPath !== "/labels",
                    "before:border-l-indigo-500": router.asPath === "/labels",
                  },
                  "flex rounded before:my-1.5 before:border-l-2 before:pl-2",
                )}
              >
                <System.Link
                  href="/labels"
                  className="flex flex-1 items-center rounded-sm p-1 text-sm font-medium transition hover:bg-slate-100"
                >
                  <System.Svg.Label className="mr-2 h-4 w-4" />
                  Labels
                </System.Link>
              </div>
            )}
            {canListTeamMembers && (
              <div
                className={classNames(
                  {
                    "before:border-l-transparent":
                      router.asPath !== "/workspace/members",
                    "before:border-l-indigo-500":
                      router.asPath === "/workspace/members",
                  },
                  "flex rounded before:my-1.5 before:border-l-2 before:pl-2",
                )}
              >
                <System.Link
                  href="/workspace/members"
                  className="flex flex-1 items-center rounded-sm p-1 text-sm font-medium transition hover:bg-indigo-50"
                >
                  <System.Svg.Team className="mr-2 h-4 w-4" />
                  Team
                </System.Link>
              </div>
            )}
            {canListMaintenanceCustomers && (
              <div
                className={classNames(
                  {
                    "before:border-l-transparent": router.asPath !== "/clients",
                    "before:border-l-indigo-500": router.asPath === "/clients",
                  },
                  "flex rounded before:my-1.5 before:border-l-2 before:pl-2",
                )}
              >
                <System.Link
                  href="/clients"
                  className="flex flex-1 items-center rounded-sm p-1 text-sm font-medium transition hover:bg-indigo-50"
                >
                  <System.Svg.AddressBook className="mr-2 h-4 w-4" />
                  Clients
                </System.Link>
              </div>
            )}
          </nav>
        )}
      </div>
    </>
  )
}
