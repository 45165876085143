import { isNil } from "lodash"
import { getDiffDayCreatedAtUser } from "./getDiffDayCreatedAtUser"

/**
 * We don't use data from user anymore, we use the date of the creation of the user.
 */
export const TRIAL_DAYS = 14

export const isCurrentTrial = (props: { userCreatedAt: string }) => {
  if (isNil(props.userCreatedAt)) {
    return true
  }

  const diffDayCreatedAt = getDiffDayCreatedAtUser(props)

  if (diffDayCreatedAt <= TRIAL_DAYS) {
    return true
  }

  return false
}
