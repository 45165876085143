import { Fragment, Suspense, useContext, useEffect } from "react"
import { Dialog, Transition } from "@headlessui/react"

import ErrorBoundary from "../../../components/ErrorBoundary"
import { SlideOverUserNotificationsContext } from "../../../contexts/SlideOverUserNotificationsContext"
import SuspenseFallbackDefault from "../../../components/SuspenseFallback/Default"
import MarkAsReadNotifications from "./components/MarkAsReadNotifications"
import SlideOverListNotifications from "../../../components/UserNotification/SlideOverListNotifications"
import { XMarkIcon } from "@heroicons/react/24/outline"
import System from "../../../ui/System"
import ShowMoreNotifications from "./components/ShowMoreNotifications"

export default function SlideOverUserNotifications() {
  const { values, actions } = useContext(SlideOverUserNotificationsContext)

  useEffect(() => {
    return () => {
      actions.setOpen(false)
    }
  }, [])

  return (
    <ErrorBoundary safeError={true}>
      <Transition.Root show={values.open} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 overflow-hidden"
          style={{ zIndex: 8999 }}
          onClose={() => actions.setOpen(false)}
        >
          <div className="absolute inset-0 overflow-hidden">
            <Transition.Child
              as={Fragment}
              enter="ease-in-out duration-500"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in-out duration-500"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="absolute inset-0 bg-gray-500 bg-opacity-25 transition-opacity" />
            </Transition.Child>

            <div className="fixed inset-y-0 right-0 flex max-w-full pl-10">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <div className="w-screen max-w-lg">
                  <div className="flex h-full flex-col overflow-y-scroll bg-neutral-100 shadow-xl">
                    <div className="border-b-2 border-gray-200 bg-white p-6">
                      <div className="flex items-start justify-between">
                        <Dialog.Title>
                          <System.Title as={"span"}>Notifications</System.Title>
                        </Dialog.Title>

                        <div className="ml-3 flex h-7 items-center">
                          <button
                            type="button"
                            className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none"
                            onClick={() => actions.setOpen(false)}
                          >
                            <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                          </button>
                        </div>
                      </div>
                      <Suspense fallback={<SuspenseFallbackDefault />}>
                        <MarkAsReadNotifications />
                      </Suspense>
                    </div>
                    <div className="relative flex-1 pb-9">
                      <SlideOverListNotifications />
                    </div>
                    <ShowMoreNotifications />
                  </div>
                </div>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </ErrorBoundary>
  )
}
