import { isEmpty, isNil } from "lodash"
import { CapabilityType } from "@prisma/client"
import { useMeCapabilities } from "../../useMeCapabilities"
import useUser from "@hooks/user/useUser"

export const useUserCanListMaintenanceCustomers = (): boolean => {
  const { isMainAccount } = useUser()

  const { data } = useMeCapabilities({
    needMakeRequest: !isMainAccount,
  })

  if (isMainAccount) {
    return true
  }

  if (isNil(data) || isEmpty(data)) {
    return false
  }

  return data.includes(CapabilityType.MAINTENANCE_CUSTOMER_LIST)
}
