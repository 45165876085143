import { get, isNil } from "lodash"
import { SVGBackupDownload } from "../../../../../svg/BackupDownload"
import UserNotificationModel from "../../../../Core/Models/Notifications/UserNotificationModel"
import BaseStrategyUserNotification from "../BaseStrategyUserNotification"
import { ResponseMessageData } from "../StrategyUserNotification"
import { FetcherProject } from "@/Project/Fetcher"
import { RequestDownloadBackupType } from "@prisma/client"

class UserNotificationBackupDownloaded extends BaseStrategyUserNotification {
  protected getBadge() {
    return {
      icon: SVGBackupDownload,
      title: "Backup",
    }
  }

  public getMessageDataSuccess(
    notification: UserNotificationModel,
  ): ResponseMessageData {
    const name = notification.getProjectName()

    const message = `The backup of [${name}] is ready. Click here to download it`

    return {
      message: message,
      badge: this.getBadge(),
      icons: this.getIcons(notification),
      onClick: async () => {
        const { data, code } =
          await FetcherProject.Backup.getBackupDownloadLinkFile({
            projectId: get(notification, "entities.project.id", 0),
            backupId: get(notification, "entities.backup.id", 0),
            type: get(
              notification,
              "entities.backup.type",
              RequestDownloadBackupType.FULL,
            ),
          })

        if (!isNil(data) && code === "success") {
          window.open(data.download_link, "_blank")
          return
        }
      },
    }
  }

  public getMessageDataError(notification: UserNotificationModel) {
    return {
      title: `Backup download preparation failed`,
      message: `An error occurred during the creation of your backup download. We invite you to contact our support to help you with the procedure.`,
      icon: {
        component: SVGBackupDownload,
        className: "text-red-500",
      },
    }
  }
}

export default UserNotificationBackupDownloaded
