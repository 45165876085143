import { Fragment, useEffect, useRef, useState } from "react"
import { Menu, Transition } from "@headlessui/react"
import { useCsrfToken } from "@hooks/useCsrfToken"
import SingleWorkspaceModel from "@/Workspace/Model/SingleWorkspaceModel"
import useUser from "@hooks/user/useUser"
import { useWorkspaces } from "@components/Workspace/hooks/useWorkspaces"
import UserModel from "@/Core/Models/User/UserModel"
import { System } from "@design-system/index"
import { isNil } from "lodash"

interface Props {
  workspace: SingleWorkspaceModel
}

const renderLogo = ({ workspace }: { workspace: SingleWorkspaceModel }) => {
  if (workspace?.logo) {
    return (
      <img
        src={workspace.logo}
        className="h-6 w-6 rounded-sm object-cover"
        alt={workspace.name}
      />
    )
  }

  return (
    <span className="flex h-6 w-6 items-center justify-center rounded-sm bg-gray-200 text-center text-xs font-bold">
      {workspace?.name.substring(0, 2).toUpperCase()}
    </span>
  )
}

const WorkspaceMenuItem = ({ workspace }: Props) => {
  const formRef = useRef(null)
  const csrfToken = useCsrfToken()

  const onClick = () => {
    formRef.current.submit()
  }

  return (
    <Menu.Item>
      <div
        className="flex cursor-pointer items-center gap-2 rounded-md px-2 py-1.5 transition hover:bg-slate-50"
        onClick={onClick}
      >
        {renderLogo({ workspace })}
        <System.Text>{workspace?.name}</System.Text>
        <System.Badges.Information color="gray" className="ml-auto">
          <System.Text as="span" size="small">
            {workspace?.total_projects}
          </System.Text>
        </System.Badges.Information>
        <form
          action="/api/auth/callback/credentials_team_member"
          method="post"
          ref={formRef}
        >
          <input name="csrfToken" type="hidden" defaultValue={csrfToken} />
          <input type="hidden" value={workspace?.user_id} name="user_id" />
          <input type="hidden" value="/projects" name="callbackUrl" />
        </form>
      </div>
    </Menu.Item>
  )
}

const WorkspaceCardItem = ({ workspace }: Props) => {
  const formRef = useRef(null)
  const csrfToken = useCsrfToken()

  const onClick = () => {
    formRef.current.submit()
  }

  return (
    <div
      className="flex cursor-pointer flex-col items-center justify-center gap-2 rounded-md border p-4 transition hover:bg-slate-50"
      onClick={onClick}
    >
      {renderLogo({ workspace })}
      <System.Text>{workspace?.name}</System.Text>
      <System.Badges.Information color="gray">
        <System.Text as="span" size="small">
          {workspace?.total_projects} Site
          {workspace?.total_projects > 1 && "s"}
        </System.Text>
      </System.Badges.Information>
      <form
        action="/api/auth/callback/credentials_team_member"
        method="post"
        ref={formRef}
      >
        <input name="csrfToken" type="hidden" defaultValue={csrfToken} />
        <input type="hidden" value={workspace?.user_id} name="user_id" />
        <input type="hidden" value="/projects" name="callbackUrl" />
      </form>
    </div>
  )
}

export const WorkspaceMenu = (props?: { variant?: "menu" | "card" }) => {
  const { user } = useUser({
    options: {
      withModel: true,
    },
  })

  const { variant = "menu" } = props

  const formRef = useRef(null)
  const csrfToken = useCsrfToken()

  const { data: workspaces } = useWorkspaces()
  const [needToRedirect, setNeedToRedirect] = useState(false)

  // Get and remove my workspace
  const personalWorkspace = workspaces.find((workspace) => {
    return workspace.user_id === (user as UserModel).id
  })

  const currentWorkspace = workspaces.find((workspace) => {
    return workspace.user_id === (user as UserModel).id
  })

  const currentWorkspaceName =
    currentWorkspace?.name || `${(user as UserModel).firstname}'s workspace`

  // Security if you lose access to a workspace
  useEffect(() => {
    if (workspaces.length > 1) {
      return
    }
    if (!isNil(currentWorkspace)) {
      return
    }

    if (workspaces.length === 0) {
      return
    }

    setNeedToRedirect(true)
  }, [currentWorkspace])

  useEffect(() => {
    if (!needToRedirect) {
      return
    }

    formRef.current.submit()
  }, [needToRedirect])

  return (
    <>
      {needToRedirect && (
        <form
          action="/api/auth/callback/credentials_team_member"
          method="post"
          ref={formRef}
        >
          <input name="csrfToken" type="hidden" defaultValue={csrfToken} />
          <input type="hidden" value={workspaces[0]?.user_id} name="user_id" />
          <input type="hidden" value="/projects" name="callbackUrl" />
        </form>
      )}
      {variant === "menu" && (
        <Menu as="div" className="relative">
          <Menu.Button className="group flex w-full items-center justify-between gap-2 rounded p-1 px-2 py-2 transition hover:bg-indigo-50">
            {renderLogo({ workspace: currentWorkspace })}
            <System.Text className="text-left text-sm font-medium">
              {currentWorkspaceName.length > 12 ? (
                <>{currentWorkspaceName.substring(0, 12)}...</>
              ) : (
                currentWorkspaceName
              )}
            </System.Text>
            <System.Svg.ChevronDown className="h-4 w-4" />
          </Menu.Button>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-50"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items className="absolute left-0 z-50 mt-2 w-64 origin-top-left rounded-md bg-white px-1 py-1 shadow-md ring-1 ring-black ring-opacity-5 focus:outline-none">
              <div className="mt-1 px-1 py-1">
                <System.Text size="small">My Workspace</System.Text>
              </div>
              <WorkspaceMenuItem workspace={personalWorkspace} />
              <div className="mt-1 px-1 py-1">
                <System.Text size="small">Shared Workspaces</System.Text>
              </div>
              {workspaces.length === 1 && (
                <div className="px-1 py-1">
                  <System.Text>No other workspace available</System.Text>
                </div>
              )}
              {workspaces.map((workspace) => {
                if (workspace.user_id === (user as UserModel).id) {
                  return null
                }
                return (
                  <WorkspaceMenuItem key={workspace.id} workspace={workspace} />
                )
              })}
            </Menu.Items>
          </Transition>
        </Menu>
      )}
      {variant === "card" && (
        <div className="grid grid-cols-2 gap-8">
          {workspaces.map((workspace) => {
            if (workspace.user_id === (user as UserModel).id) {
              return null
            }
            return (
              <WorkspaceCardItem key={workspace.id} workspace={workspace} />
            )
          })}
          <WorkspaceCardItem workspace={personalWorkspace} />
        </div>
      )}
    </>
  )
}
