import { Suspense, useEffect } from "react"

import { System } from "@design-system/index"
import Config from "@environments/data"
import { InitialDataContextProvider } from "../contexts/InitialDataContext"
import isServer from "../helpers/isServer"
import useTokenInit from "../hooks/user/useTokenInit"
import useUser from "../hooks/user/useUser"
import SlideOverDefault from "./SlideOver/Default"

//@ts-ignore
import SecurityApplication from "@components/ApplicationSecurity/Application"
import useUserIsPremium from "@hooks/user/useUserIsPremium"
import { useSession } from "next-auth/react"
import Router, { useRouter } from "next/router"
import BeaconComponent from "./Beacon"
import SlideOverUserNotifications from "./SlideOver/SlideOverUserNotifications"
import { StructureLayout } from "./Structure"

const WHITELIST_TRIAL = ["/join-connected", "/join", "/trial-over"]

const TrialCheckContainer = ({ children }) => {
  const { isMainAccount, isLoading: isUserLoading } = useUser({
    suspense: true,
  })
  const isPremiumUser = useUserIsPremium()
  const router = useRouter()

  useEffect(() => {
    if (isUserLoading) {
      return
    }

    if (!isMainAccount) {
      return
    }

    if (WHITELIST_TRIAL.includes(router.pathname)) {
      return
    }

    if (!isPremiumUser) {
      Router.push("/trial-over")
    }
  }, [isMainAccount, isPremiumUser, isUserLoading])

  return <Suspense fallback={null}>{children}</Suspense>
}

const App = ({
  //@ts-ignore
  children,
  initialData = {},
}) => {
  useTokenInit({
    redirectTo: Config.pages.auth.login,
    redirectIfFound: false,
  })

  const { status } = useSession()

  if (status === "loading" || status === "unauthenticated") {
    return <System.SuspenseFallback.Security />
  }

  return (
    <TrialCheckContainer>
      <SecurityApplication>
        <InitialDataContextProvider values={initialData}>
          {!isServer() && (
            <Suspense
              fallback={
                <>
                  <System.SuspenseFallback.Security />
                </>
              }
            >
              <System.Context.GenericModal.Provider>
                <System.Context.GenericApplication.Provider>
                  <StructureLayout.TopMenu />
                  <StructureLayout.LeftMenu />
                  <StructureLayout.Container>
                    {children}
                  </StructureLayout.Container>
                </System.Context.GenericApplication.Provider>
              </System.Context.GenericModal.Provider>
              <SlideOverDefault />
              <SlideOverUserNotifications />
              <BeaconComponent />
            </Suspense>
          )}
        </InitialDataContextProvider>
      </SecurityApplication>
    </TrialCheckContainer>
  )
}

export default App
