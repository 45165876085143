import { User } from "@/Types/User/User"
import { System } from "@design-system/index"
import classNames from "classnames"
import { isNil } from "lodash"
import { getCsrfToken } from "next-auth/react"
import { useEffect, useRef, useState } from "react"

export const AsAdminBar = ({
  isAdminLogInAs,
}: {
  isAdminLogInAs: {
    user: User
  }
}) => {
  const formRef = useRef(null)
  const [csrfToken, setCsrfToken] = useState(null)

  useEffect(() => {
    const getCsrf = async () => {
      setCsrfToken(await getCsrfToken())
    }
    getCsrf()
  }, [])

  if (isNil(isAdminLogInAs)) {
    return
  }

  return (
    <>
      <div
        className={classNames(
          "fixed top-0 flex h-14 w-full items-center justify-center gap-4 bg-gradient-to-r from-red-600 via-red-700 to-red-600 text-white",
        )}
        style={{
          zIndex: 1500,
        }}
      >
        <strong>WARNING</strong> : We are connected {`"`}as a as {`"`} a user.
        Take all precautions necessary.
        <form
          action="/api/auth/callback/credentials_admin"
          method="post"
          onClick={(e) => {
            e.preventDefault()
            formRef.current && formRef.current.submit()
          }}
          ref={formRef}
        >
          <input name="csrfToken" type="hidden" defaultValue={csrfToken} />
          <input type="hidden" value={isAdminLogInAs.user.id} name="user_id" />
          <System.Form.Submit className="w-full justify-center bg-white !text-black">
            Back as admin
          </System.Form.Submit>
        </form>
      </div>
    </>
  )
}
