import AnnounceKit from "announcekit-react"
import classNames from "classnames"
import gravatarUrl from "gravatar-url"
import { first, get, isEmpty, isNil } from "lodash"
import { Suspense, useContext } from "react"
import Config from "@environments/data"
import { useMeCountNotificationsNotRead } from "@components/Profile/hooks/useMeCountNotificationsNotRead"
import { SlideOverUserNotificationsContext } from "@contexts/SlideOverUserNotificationsContext"
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@design-system/Shadcn/ui/dropdown-menu"
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@design-system/Shadcn/ui/tooltip"
import { System } from "@design-system/index"
import {
  ArrowPathIcon,
  BellIcon,
  GiftIcon,
  KeyIcon,
} from "@heroicons/react/24/outline"
import useNotifications from "@hooks/notifications/useNotifications"
import useResyncProjects from "@hooks/projects/useResyncProjects"
import useApiKey from "@hooks/user/useApiKey"
import useUser from "@hooks/user/useUser"

import { User } from "@/Types/User/User"
import { goToStripeBillingDetails } from "@components/Profile/Subscription/helpers/goToStripeBillingDetails"
import { TrialBar } from "./TrialBar"
import { isCurrentTrial } from "@helpers/user/isCurrentTrial"
import { AsAdminBar } from "./AdminBar"
import { SlideOverAddWebsite } from "@layouts/SlideOver/SlideOverAddWebsite"
import { SlideOverContext } from "@contexts/SlideOverContext"
import useUserIsPremium from "@hooks/user/useUserIsPremium"

const ApiKey = () => {
  const apiKey = useApiKey()
  const { handleNotifySuccess } = useNotifications()
  return (
    <>
      <System.CopyToClipboard.Copiable
        text={apiKey}
        onCopy={() => {
          handleNotifySuccess({
            title: "API Key Copied!",
            message: (
              <>
                The API key (
                <strong>
                  {apiKey.substr(0, 10)}
                  ...
                </strong>
                ) is in your clipboard.
              </>
            ),
          })
        }}
      >
        {() => {
          return (
            <div className="group flex items-center gap-2 transition-all">
              <KeyIcon className="h-4 w-4" />
              <TooltipProvider delayDuration={0}>
                <Tooltip>
                  <TooltipTrigger className="border-b border-b-transparent text-sm group-hover:border-dashed group-hover:border-b-slate-400">
                    API Key
                  </TooltipTrigger>
                  <TooltipContent className="bg-slate-700 text-slate-50">
                    <p>Click to copy your API Key</p>
                  </TooltipContent>
                </Tooltip>
              </TooltipProvider>
            </div>
          )
        }}
      </System.CopyToClipboard.Copiable>
    </>
  )
}

const Notifications = () => {
  const { data: notificationsData } = useMeCountNotificationsNotRead()
  const total = get(notificationsData, "total", 0)
  const { actions: actionsUserNotifications } = useContext(
    SlideOverUserNotificationsContext,
  )

  return (
    <div
      className={classNames(
        {
          "before:absolute before:right-[4px] before:top-[3px] before:h-2.5 before:w-2.5 before:rounded-full before:border before:border-white before:bg-indigo-600":
            total > 0,
        },
        "relative cursor-pointer rounded-full p-1 transition hover:bg-slate-100",
      )}
      onClick={() => actionsUserNotifications.setOpen(true)}
    >
      <TooltipProvider delayDuration={0}>
        <Tooltip>
          <TooltipTrigger className="border-b border-b-transparent text-sm group-hover:border-dashed group-hover:border-b-slate-400">
            <BellIcon className="h-5 w-5" />
          </TooltipTrigger>
          <TooltipContent className="bg-slate-700 text-slate-50">
            <p>Notifications</p>
          </TooltipContent>
        </Tooltip>
      </TooltipProvider>
    </div>
  )
}

const Resync = () => {
  const {
    handleResyncData,
    loading: loadingResyncData,
    processes: processesSyncData,
  } = useResyncProjects()

  const loadingResync = !isEmpty(processesSyncData) || loadingResyncData
  const process = first(processesSyncData)
  let onlyOneProcess = false
  if (!isNil(process)) {
    onlyOneProcess = process.tasks.length === 1
  }

  return (
    <div
      className="cursor-pointer rounded-full p-1 transition hover:bg-slate-100"
      onClick={async () => {
        await handleResyncData()
      }}
    >
      <TooltipProvider delayDuration={0}>
        <Tooltip>
          <TooltipTrigger className="animate border-b border-b-transparent text-sm group-hover:border-dashed group-hover:border-b-slate-400">
            <ArrowPathIcon
              className={classNames(
                {
                  "animate-spin": loadingResync && !onlyOneProcess,
                },
                "h-5 w-5",
              )}
            />
          </TooltipTrigger>
          <TooltipContent className="bg-slate-700 text-slate-50">
            <p>Re-sync your projects</p>
          </TooltipContent>
        </Tooltip>
      </TooltipProvider>
    </div>
  )
}

const AnnounceKitComponent = ({ user }: { user: User }) => {
  return (
    <>
      <div
        className={classNames(
          "relative cursor-pointer rounded-full p-1 transition hover:bg-slate-100",
        )}
      >
        {/* @ts-ignore */}
        <AnnounceKit
          widget="https://changelog.wp-umbrella.com/widgets/v2/1JXTyg"
          widgetStyle={{
            position: "absolute",
            top: "-10px",
            right: "-7px",
            margin: "0",
            border: "1px solid #ffffff",
          }}
          user={{
            id: String(get(user, "id", "")),
            email: String(get(user, "email", "")),
          }}
        >
          <TooltipProvider delayDuration={0}>
            <Tooltip>
              <TooltipTrigger className="animate border-b border-b-transparent text-sm group-hover:border-dashed group-hover:border-b-slate-400">
                <GiftIcon className="h-5 w-5" />
              </TooltipTrigger>
              <TooltipContent className="bg-slate-700 text-slate-50">
                <p>Product Updates</p>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
        </AnnounceKit>
      </div>
    </>
  )
}

const UserMenu = ({ user }: { user: User }) => {
  const { isMainAccount } = useUser<User>({
    options: {
      withModel: true,
    },
  })

  return (
    <DropdownMenu>
      <DropdownMenuTrigger>
        <img
          src={gravatarUrl(get(user, "email", ""), {
            size: 48,
            default: "mm",
          })}
          className="h-9 w-9 rounded-full border p-1"
        />
      </DropdownMenuTrigger>
      <DropdownMenuContent
        className="mr-10 w-52"
        style={{
          zIndex: 1503,
        }}
      >
        <DropdownMenuLabel className="mb-0 pb-0 text-sm font-normal">
          {user.firstname} {user.lastname}
        </DropdownMenuLabel>
        <DropdownMenuLabel className="break-words pt-1">
          {user.email}
        </DropdownMenuLabel>
        <DropdownMenuSeparator />
        <DropdownMenuItem>
          <System.Link
            naked
            href="/profile"
            className="flex w-full items-center gap-2"
          >
            <System.Svg.User className="h-4 w-4 opacity-50" />
            Profile
          </System.Link>
        </DropdownMenuItem>
        {isMainAccount && (
          <DropdownMenuItem>
            <System.Link
              naked
              href="/profile/subscription"
              className="flex w-full items-center gap-2"
            >
              <System.Svg.CreditCard className="h-4 w-4 opacity-50" />
              Subscription
            </System.Link>
          </DropdownMenuItem>
        )}
        <DropdownMenuItem>
          <System.Link
            naked
            href="/support"
            className="flex w-full items-center gap-2"
          >
            <System.Svg.Support className="h-4 w-4 opacity-50" />
            Support
          </System.Link>
        </DropdownMenuItem>

        <DropdownMenuSeparator />
        {isMainAccount && (
          <DropdownMenuItem
            className="flex w-full cursor-pointer items-center gap-2"
            onClick={goToStripeBillingDetails}
          >
            <System.Svg.Receipt className="h-4 w-4 opacity-50" />
            Invoices
            <System.Svg.External className="ml-auto h-3 w-3 opacity-50" />
          </DropdownMenuItem>
        )}
        <DropdownMenuItem>
          <System.Link
            href="https://wordpress.org/plugins/wp-health/"
            target="_blank"
            naked
            className="flex w-full cursor-pointer items-center gap-2"
          >
            <>
              <System.Svg.Download className="h-4 w-4 opacity-50" />
              Download Plugin
              <System.Svg.External className="ml-auto h-3 w-3 opacity-50" />
            </>
          </System.Link>
        </DropdownMenuItem>
        <DropdownMenuItem>
          <System.Link
            href="https://wp-umbrella.canny.io/"
            target="_blank"
            className="flex w-full cursor-pointer items-center gap-2"
          >
            <>
              <System.Svg.Road className="h-4 w-4 opacity-50" />
              Product Roadmap
              <System.Svg.External className="ml-auto h-3 w-3 opacity-50" />
            </>
          </System.Link>
        </DropdownMenuItem>
        <DropdownMenuSeparator />
        <DropdownMenuItem>
          <System.Link
            href={Config.pages.auth.logout}
            naked
            className="flex w-full cursor-pointer items-center gap-2"
          >
            <System.Svg.Logout className="h-4 w-4 opacity-50" />
            Logout
          </System.Link>
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  )
}

export const TopMenu = () => {
  const { mainUser: user, isAdminLogInAs } = useUser<User>({
    options: {
      withModel: true,
    },
  })

  const createdAt = get(user, "created_at")

  const { actions: actionsSlideOver } = useContext(SlideOverContext)

  const isTrial = isCurrentTrial({
    userCreatedAt: String(createdAt),
  })

  const isPremiumUser = useUserIsPremium({ checkIsTrial: false })

  return (
    <>
      <AsAdminBar isAdminLogInAs={isAdminLogInAs} />
      <Suspense fallback={null}>
        <TrialBar />
      </Suspense>
      <div
        className={classNames(
          {
            "top-0": !isTrial && isPremiumUser && isNil(isAdminLogInAs),
            "top-14":
              (isTrial && !isPremiumUser && isNil(isAdminLogInAs)) ||
              (isPremiumUser && !isNil(isAdminLogInAs)) ||
              (!isTrial && !isPremiumUser && !isNil(isAdminLogInAs)),
            "top-28": isTrial && !isPremiumUser && !isNil(isAdminLogInAs),
          },
          "fixed left-0 right-0 top-0 w-full min-w-[700px] bg-gradient-to-b from-white to-white/80 pl-[240px] shadow-sm backdrop-blur-sm",
        )}
        style={{
          zIndex: 1500,
        }}
      >
        <div className="mx-auto max-w-[2650px]">
          <div className="flex h-16 items-center px-4">
            <div className="ml-auto mr-8 flex items-center space-x-4">
              <div className="group flex cursor-pointer items-center gap-2 p-1 transition-all">
                <System.Svg.Support className="h-4 w-4" />
                <TooltipProvider delayDuration={0}>
                  <Tooltip>
                    <TooltipTrigger className="border-b border-b-transparent text-sm group-hover:border-dashed group-hover:border-b-slate-400">
                      <System.Link href="/support" naked>
                        Support
                      </System.Link>
                    </TooltipTrigger>
                    <TooltipContent className="bg-slate-700 text-slate-50">
                      <p>Help & Support</p>
                    </TooltipContent>
                  </Tooltip>
                </TooltipProvider>
              </div>
              <div>
                <Suspense fallback={null}>
                  <ApiKey />
                </Suspense>
              </div>
              <System.Button.Primary
                size="small"
                onClick={() => {
                  actionsSlideOver.setData({
                    title: "How To Add Websites To The Dashboard",
                    description:
                      "You just need install WP Umbrella’s plugin and connect your API key! 🙂",
                    component: <SlideOverAddWebsite />,
                  })
                }}
              >
                <System.Svg.Plus className="h-4 w-4" />
                Add Site
              </System.Button.Primary>
              <div>
                <Suspense fallback={null}>
                  <Notifications />
                </Suspense>
              </div>
              <div>
                <Suspense fallback={null}>
                  <Resync />
                </Suspense>
              </div>
              <div>
                <Suspense fallback={null}>
                  <AnnounceKitComponent user={user} />
                </Suspense>
              </div>
              <div className="cursor-pointer rounded-full p-1 transition hover:bg-slate-100">
                <UserMenu user={user} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
