import { User } from "@/Types/User/User"
import { useApplication } from "@design-system/Context/GenericApplication/hooks/useApplication"
import { isCurrentTrial } from "@helpers/user/isCurrentTrial"
import { cn } from "@helpers/utils"
import useUser from "@hooks/user/useUser"
import useUserIsPremium from "@hooks/user/useUserIsPremium"
import classNames from "classnames"
import { isNil } from "lodash"
import { PropsWithChildren } from "react"

export const Container = ({ children }: PropsWithChildren) => {
  const { mainUser: user, isAdminLogInAs } = useUser<User>({
    options: {
      withModel: true,
    },
  })

  const isTrial = isCurrentTrial({
    userCreatedAt: String(user.created_at),
  })

  const { state } = useApplication()

  const isPremiumUser = useUserIsPremium({ checkIsTrial: false })

  return (
    <div
      className={classNames(
        {
          "mt-0": !isTrial && isPremiumUser && isNil(isAdminLogInAs),
          "mt-14":
            (isTrial && !isPremiumUser && isNil(isAdminLogInAs)) ||
            (isPremiumUser && !isNil(isAdminLogInAs)) ||
            (!isTrial && !isPremiumUser && !isNil(isAdminLogInAs)),
          "mt-28": isTrial && !isPremiumUser && !isNil(isAdminLogInAs),
        },
        "w-full min-w-[1400px] pl-[240px] pt-16",
        cn("pb-16 pt-16", state.container.className),
      )}
    >
      {children}
    </div>
  )
}
