import { System } from "@design-system/index"
import { isEmpty } from "lodash"
import UserNotificationModel from "../../../../Core/Models/Notifications/UserNotificationModel"
import BaseStrategyUserNotification from "../BaseStrategyUserNotification"
import { ResponseMessageData } from "../StrategyUserNotification"

class UserNotificationScanProject extends BaseStrategyUserNotification {
  protected getBadge() {
    return {
      icon: System.Svg.Shield,
      title: "Security Scan",
    }
  }

  public getMessageDataSuccess(
    notification: UserNotificationModel,
  ): ResponseMessageData {
    const { fails } = this.getTotalTasks(notification)

    const name = notification.getProjectName()

    let message = ""
    if (fails === 0) {
      message = `Scan Successfully Completed. 👍`
    } else {
      message = `Completed. Your attention might be needed.`
    }

    if (!isEmpty(name)) {
      message = `[${name}] - ${message}`
    }

    return {
      message: message,
      badge: this.getBadge(),
      icons: this.getIcons(notification),
      link: `/projects/${notification.getProjectId()}/security`,
    }
  }

  public getMessageDataError(
    notification: UserNotificationModel,
  ): ResponseMessageData {
    return {
      message: `Something wrong happened during the vulnerability scan. Please contact support.`,
      badge: this.getBadge(),
      icons: this.getIcons(notification),
      link: `/projects/${notification.getProjectId()}/security`,
    }
  }
}

export default UserNotificationScanProject
