import useNotifications from "@hooks/notifications/useNotifications"
import useApiKey from "@hooks/user/useApiKey"
import System from "@ui/System"
import CopyToClipboard from "react-copy-to-clipboard"

export function SlideOverAddWebsite() {
  const apiKey = useApiKey()

  const { handleNotifySuccess } = useNotifications()

  return (
    <>
      <System.Text large className="mb-8">
        To add a website to the dashboard, you need to install WP Umbrella{`'`}s
        worker on your website.
      </System.Text>
      <System.Text large className="mt-8">
        <strong>Step 1:</strong> Download WP Umbrella from here or from
        WordPress{`'`}s plugin directory.
      </System.Text>
      <img
        src="/images/slideover/add-website/step-1.png"
        className="mt-4 w-full"
      />
      <System.Text large className="mt-8">
        <strong>Step 2:</strong> Go to WordPress admin {">>"} Settings {">>"} WP
        Umbrella.
      </System.Text>
      <img
        src="/images/slideover/add-website/step-2.png"
        className="m-auto mt-4 h-60"
      />
      <System.Text large className="mt-8">
        <strong>Step 3:</strong>{" "}
        <CopyToClipboard
          text={apiKey}
          onCopy={() => {
            handleNotifySuccess({
              title: "API Key Copied!",
              message: (
                <>
                  The API key (
                  <strong>
                    {apiKey.substr(0, 10)}
                    ...
                  </strong>
                  ) is in your clipboard.
                </>
              ),
            })
          }}
        >
          <span className="cursor-pointer text-blue-500 underline">
            Copy and paste your API Key
          </span>
        </CopyToClipboard>{" "}
        and click on save. The website will be added automatically to your
        dashboard!
      </System.Text>
      <img
        src="/images/slideover/add-website/step-3.png"
        className="mt-4 w-full"
      />
    </>
  )
}
