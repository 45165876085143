import UserNotificationModel from "@/Core/Models/Notifications/UserNotificationModel"
import BaseStrategyUserNotification from "../BaseStrategyUserNotification"
import { System } from "@design-system/index"

class UserNotificationBulkProcessPluginDeactivated extends BaseStrategyUserNotification {
  protected getBadge() {
    return {
      icon: System.Svg.Plugin,
      title: "Plugins Deactivated",
    }
  }

  public getMessageDataSuccess(notification: UserNotificationModel) {
    const { fails } = this.getTotalTasks(notification)

    let message = ""
    if (fails === 0) {
      message = `Successfully Completed. 👍`
    } else {
      message = `Completed. Your attention might be needed.`
    }

    return {
      message: message,
      badge: this.getBadge(),
      icons: this.getIcons(notification),
    }
  }

  public getMessageDataError(notification: UserNotificationModel) {
    return {
      message: `The plugin deactivation task had an error`,
      badge: this.getBadge(),
      icons: this.getIcons(notification),
    }
  }
}

export default UserNotificationBulkProcessPluginDeactivated
