import useSWR from "swr"
import qs from "query-string"
import { getMeNotificationsPath } from "../../src/FetchApi/Me"
import MorphUserNotification from "../../src/Middlewares/SWR/MorphUserNotification"

interface Props {
  page?: number
  suspense?: boolean
}

function useUserNotifications<T>(
  { suspense = true, page = 1 }: Props = { suspense: true },
) {
  const path = qs.stringifyUrl({
    url: getMeNotificationsPath(),
    query: {
      page,
    },
  })

  return useSWR<T>(path, {
    suspense: suspense,
    use: [MorphUserNotification],
  })
}

export default useUserNotifications
