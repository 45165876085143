import useUser from "../../../../../hooks/user/useUser"
import useUserNotifications from "../../../../../hooks/user/useUserNotifications"
import UserNotificationModel from "../../../../../src/Core/Models/Notifications/UserNotificationModel"
import FetchApiMe from "../../../../../src/FetchApi/Me"
import System from "../../../../../ui/System"

const MarkAsReadNotifications = () => {
  const { data: notifications, mutate } =
    useUserNotifications<Array<UserNotificationModel>>()

  const { mutate: mutateUser, data: dataUser } = useUser()

  return (
    <System.Text
      className="inline-block cursor-pointer text-gray-400 underline"
      onClick={async () => {
        mutate(
          notifications.map((notification) => {
            notification.read = true
            return notification
          }),
          false,
        )
        mutateUser(
          {
            ...dataUser,
            user: {
              ...dataUser.user,
              total_notifications_not_read: 0,
            },
          },
          false,
        )

        await FetchApiMe.updateReadNotifications()

        mutate()
        mutateUser()
      }}
    >
      Mark everything as read
    </System.Text>
  )
}

export default MarkAsReadNotifications
