import { Workspace } from "@prisma/client"
import { createSchema } from "morphism"

interface TargetSingleWorkspaceModel {
  id: string
  name: string
  logo: string | null
  user_id: number
  created_at: string
  updated_at: string
  total_projects: number
}

export const SchemaSingleWorkspaceModel = createSchema<
  TargetSingleWorkspaceModel,
  Workspace
>({
  id: "id",
  name: "name",
  logo: "logo",
  user_id: "user_id",
  created_at: "created_at",
  updated_at: "updated_at",
  total_projects: "total_projects",
})

class SingleWorkspaceModel implements TargetSingleWorkspaceModel {
  id: string
  name: string
  logo: string | null
  user_id: number
  created_at: string
  updated_at: string
  total_projects: number
}

export default SingleWorkspaceModel
