import { useContext } from "react"
import { SlideOverUserNotificationsContext } from "../../../../../contexts/SlideOverUserNotificationsContext"
import System from "../../../../../ui/System"

const ShowMoreNotifications = () => {
  const { actions } = useContext(SlideOverUserNotificationsContext)

  return (
    <div className="absolute bottom-0 w-full cursor-pointer border-t-2 border-gray-200 bg-white px-6 py-4 transition hover:bg-indigo-50">
      <System.Text
        className="text-center font-medium"
        onClick={async () => {
          actions.setPage((page) => page + 1)
        }}
      >
        Show More Notifications
      </System.Text>
    </div>
  )
}

export default ShowMoreNotifications
