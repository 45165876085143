import React from "react"
import { get, isNil } from "lodash"
import Config from "../../environments"

interface Props {
  title?: string
  safeError?: boolean
  render?: Function
  key?: any
  callback?: Function
  children?: any
}

class ErrorBoundary extends React.Component<Props> {
  constructor(props) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError(error) {
    return { hasError: true }
  }

  componentDidCatch(error, errorInfo) {
    if (!Config.production) {
      console.error("[error]", error)
      console.error("[errorInfo]", errorInfo)
      return
    }

    if (!isNil(this.props.callback)) {
      this.props.callback(error)
    }
  }

  render() {
    const render = get(this.props, "render", null)
    const titleError = get(this.props, "title", "Something went wrong.")

    // @ts-ignore
    if (this.state.hasError && get(this, "props.safeError", false)) {
      return null
    }
    // @ts-ignore
    if (this.state.hasError) {
      if (!isNil(render)) {
        return <>{render()}</>
      } else {
        return (
          <div className="p-2">
            <h2 className="text-sm font-bold">{titleError}</h2>
            <p className="text-sm">
              Please{" "}
              <a
                href="mailto:support@wp-umbrella.com"
                className="text-blue-500 underline"
              >
                contact support
              </a>{" "}
              with a screenshot to help us solve the problem.
            </p>
          </div>
        )
      }
    }

    return this.props.children
  }
}

export default ErrorBoundary
