import { Fragment, useContext, useEffect } from "react"
import { Dialog, Transition } from "@headlessui/react"
import { XMarkIcon } from "@heroicons/react/24/outline"
import { SlideOverContext } from "../../../contexts/SlideOverContext"
import ErrorBoundary from "../../../components/ErrorBoundary"
import { isNil } from "lodash"

export default function SlideOverDefault() {
  const { values, actions } = useContext(SlideOverContext)

  useEffect(() => {
    return () => {
      actions.setOpen(false)
    }
  }, [])

  return (
    <ErrorBoundary safeError={true}>
      <Transition.Root show={values.open} as={Fragment}>
        <Dialog
          as="div"
          static
          className="fixed inset-0 z-50 overflow-hidden"
          style={{ zIndex: 8999 }}
          open={values.open}
          onClose={() => {
            if (
              !isNil(values.data.canCloseByClickOutside) &&
              !values.data.canCloseByClickOutside
            ) {
              return
            }
            actions.setOpen(false)
          }}
        >
          <div className="absolute inset-0 overflow-hidden">
            <Dialog.Overlay className="absolute inset-0" />

            <div className="fixed inset-y-0 right-0 flex max-w-full pl-16">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-300"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-300"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <div className="w-screen max-w-md">
                  <div className="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl">
                    <div className="h-0 flex-1 overflow-y-auto">
                      <div className="bg-indigo-700 px-4 py-6 sm:px-6">
                        <div className="flex items-center justify-between">
                          <Dialog.Title className="text-lg font-medium text-white">
                            {values.data.title}
                          </Dialog.Title>
                          <div className="ml-3 flex h-7 items-center">
                            <button
                              type="button"
                              className="rounded-md bg-indigo-700 text-indigo-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                              onClick={() => actions.setOpen(false)}
                            >
                              <span className="sr-only">Close panel</span>
                              <XMarkIcon
                                className="h-6 w-6"
                                aria-hidden="true"
                              />
                            </button>
                          </div>
                        </div>
                        <div className="mt-1">
                          <p className="text-sm text-indigo-300">
                            {values.data.description}
                          </p>
                        </div>
                      </div>
                      <div className="px-4 py-4">{values.data.component}</div>
                    </div>
                  </div>
                </div>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </ErrorBoundary>
  )
}
