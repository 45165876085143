import { UserNotificationType } from "@prisma/client"
import UserNotificationModel from "../../Core/Models/Notifications/UserNotificationModel"
import StrategyUserNotification from "./strategy/StrategyUserNotification"

import UserNotificationBackupDownloaded from "./strategy/backups/UserNotificationBackupDownloaded"
import UserNotificationBulkProcessPluginUpdated from "./strategy/plugins/UserNotificationBulkProcessPluginUpdated"
import UserNotificationProcessPluginUpdated from "./strategy/plugins/UserNotificationProcessPluginUpdated"
import UserNotificationBulkProcessPluginDeactivated from "./strategy/plugins/UserNotificationBulkProcessPluginDeactivated"
import UserNotificationProcessPluginDeactivated from "./strategy/plugins/UserNotificationProcessPluginDeactivated"
import UserNotificationProcessPluginActivated from "./strategy/plugins/UserNotificationProcessPluginActivated"
import UserNotificationBulkProcessPluginActivated from "./strategy/plugins/UserNotificationBulkProcessPluginActivated"
import UserNotificationProcessWordPressCoreUpdate from "./strategy/core/UserNotificationProcessWordPressCoreUpdate"
import UserNotificationBulkProcessWordPressCoreUpdated from "./strategy/core/UserNotificationBulkProcessWordPressCoreUpdated"
import UserNotificationProcessThemeUpdated from "./strategy/themes/UserNotificationProcessThemeUpdated"
import UserNotificationBulkSynchroniseProjects from "./strategy/synchronise/UserNotificationBulkSynchroniseProjects"
import UserNotificationBackupDownloadedDatabase from "./strategy/backups/UserNotificationBackupDownloadedDatabase"
import UserNotificationBackupDownloadedFile from "./strategy/backups/UserNotificationBackupDownloadedFile"
import UserNotificationSynchroniseProject from "./strategy/synchronise/UserNotificationSynchroniseProject"
import UserNotificationRestoreFinished from "./strategy/restores/UserNotificationRestoreFinished"
import DefaultUserNotification from "./strategy/DefaultUserNotification"
import UserNotificationProcessPluginInstalled from "./strategy/plugins/UserNotificationProcessPluginInstalled"
import UserNotificationScanProject from "./strategy/vulnerabilities/UserNotificationScanProject"
import UserNotificationProcessPluginDeleted from "./strategy/plugins/UserNotificationProcessPluginDeleted"
import UserNotificationBulkProcessPluginDeleted from "./strategy/plugins/UserNotificationBulkProcessPluginDeleted"
import UserNotificationProcessThemeDeleted from "./strategy/themes/UserNotificationProcessThemeDeleted"
import UserNotificationBulkProcessThemeDeleted from "./strategy/themes/UserNotificationBulkProcessThemeDeleted"
import UserNotificationProcessDatabaseOptimization from "./strategy/core/UserNotificationProcessDatabaseOptimization"
import UserNotificationBulkProcessDabaseOptimization from "./strategy/core/UserNotificationBulkProcessDabaseOptimization"
import UserNotificationProcessPluginRollback from "./strategy/plugins/UserNotificationProcessPluginRollback"

export const getStrategyByNotification = (
  notification: UserNotificationModel,
): StrategyUserNotification => {
  switch (notification.type) {
    case UserNotificationType.BACKUP_DOWNLOADABLE:
      return new UserNotificationBackupDownloaded()
    case UserNotificationType.PROCESS_UPDATE_PLUGIN_FINISHED:
      return new UserNotificationProcessPluginUpdated()
    case UserNotificationType.BULK_PROCESS_UPDATE_PLUGIN_FINISHED:
      return new UserNotificationBulkProcessPluginUpdated()
    case UserNotificationType.PROCESS_UPDATE_CORE_FINISHED:
      return new UserNotificationProcessWordPressCoreUpdate()
    case UserNotificationType.BULK_PROCESS_UPDATE_CORE_FINISHED:
      return new UserNotificationBulkProcessWordPressCoreUpdated()
    case UserNotificationType.PROCESS_UPDATE_THEME_FINISHED:
      return new UserNotificationProcessThemeUpdated()
    case UserNotificationType.PROCESS_ACTIVATE_PLUGIN_FINISHED:
      return new UserNotificationProcessPluginActivated()
    case UserNotificationType.BULK_PROCESS_ACTIVATE_PLUGIN_FINISHED:
      return new UserNotificationBulkProcessPluginActivated()
    case UserNotificationType.PROCESS_DEACTIVATE_PLUGIN_FINISHED:
      return new UserNotificationProcessPluginDeactivated()
    case UserNotificationType.BULK_PROCESS_DEACTIVATE_PLUGIN_FINISHED:
      return new UserNotificationBulkProcessPluginDeactivated()
    case UserNotificationType.BULK_SYNCHRONISE_PROJECT_FINISHED:
      return new UserNotificationBulkSynchroniseProjects()
    case UserNotificationType.SYNCHRONISE_PROJECT_FINISHED:
      return new UserNotificationSynchroniseProject()
    case UserNotificationType.BACKUP_DOWNLOADABLE_DATABASE:
      return new UserNotificationBackupDownloadedDatabase()
    case UserNotificationType.BACKUP_DOWNLOADABLE_FILE:
      return new UserNotificationBackupDownloadedFile()
    case UserNotificationType.PROCESS_INSTALL_PLUGIN_FINISHED:
      return new UserNotificationProcessPluginInstalled()
    case UserNotificationType.RESTORE_FINISHED:
      return new UserNotificationRestoreFinished()
    case UserNotificationType.PROJECT_SCAN_VULNERABILITY_FINISHED:
      return new UserNotificationScanProject()
    case UserNotificationType.PROCESS_DELETE_PLUGIN_FINISHED:
      return new UserNotificationProcessPluginDeleted()
    case UserNotificationType.BULK_PROCESS_DELETE_PLUGIN_FINISHED:
      return new UserNotificationBulkProcessPluginDeleted()
    case UserNotificationType.PROCESS_DELETE_THEME_FINISHED:
      return new UserNotificationProcessThemeDeleted()
    case UserNotificationType.BULK_PROCESS_DELETE_THEME_FINISHED:
      return new UserNotificationBulkProcessThemeDeleted()
    case UserNotificationType.PROCESS_DATABASE_OPTIMIZATION_FINISHED:
      return new UserNotificationProcessDatabaseOptimization()
    case UserNotificationType.BULK_PROCESS_DATABASE_OPTIMIZATION_FINISHED:
      return new UserNotificationBulkProcessDabaseOptimization()
    case UserNotificationType.PROCESS_ROLLBACK_PLUGIN_FINISHED:
      return new UserNotificationProcessPluginRollback()
    default:
      return new DefaultUserNotification()
  }
}
