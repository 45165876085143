import React, { Suspense, useContext } from "react"
import { SlideOverUserNotificationsContext } from "../../../contexts/SlideOverUserNotificationsContext"
import useUserNotifications from "../../../hooks/user/useUserNotifications"
import UserNotificationModel from "../../../src/Core/Models/Notifications/UserNotificationModel"
import ErrorBoundary from "../../ErrorBoundary"
import SuspenseFallbackNotification from "../../SuspenseFallback/Notification"
import ItemNotification from "./SlideOverItemNotification"

const PageItem = ({ page }) => {
  const { data: notifications } = useUserNotifications<
    Array<UserNotificationModel>
  >({
    page,
  })

  if (!notifications) {
    return null
  }

  return (
    <>
      {notifications.map((notification) => (
        <ItemNotification key={notification.id} notification={notification} />
      ))}
    </>
  )
}

const SlideOverListNotifications = () => {
  const { values } = useContext(SlideOverUserNotificationsContext)

  const items = []
  for (let index = 1; index <= values.page; index++) {
    items.push(
      <React.Fragment key={index}>
        <ErrorBoundary safeError={true}>
          <PageItem key={index} page={index} />
        </ErrorBoundary>
      </React.Fragment>,
    )
  }

  return (
    <>
      <ul className="p-4">
        <Suspense fallback={<SuspenseFallbackNotification />}>{items}</Suspense>
      </ul>
    </>
  )
}

export default SlideOverListNotifications
