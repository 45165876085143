import useSWR from "swr"
import fetcherPrivateApiUmbrella from "@helpers/fetcherPrivateApiUmbrella"
import { isEmpty, isNil } from "lodash"
import { getPrivateApiMeSubscriptionPath } from "@/Profile/api/routes-private"

type Settings = {
  id_plan: string
  id_plan_hourly_backup: string
  name: string
  price: number
  slug: string
  User: {
    id: number
    created_at: string
  }
}

interface Data extends Settings {
  connected?: Settings
}

interface Props {
  suspense?: boolean
  options?: {
    refreshInterval?: number
  }
}

export const useMeSubscription = (
  { suspense, options }: Props = {
    suspense: true,
  },
) => {
  const { data: response, ...rest } = useSWR<{
    code: string
    data: Data
  }>(getPrivateApiMeSubscriptionPath(), {
    suspense: suspense,
    fetcher: fetcherPrivateApiUmbrella(),
    ...options,
  })

  const { code, data } = response || {}

  if (isNil(data) || isEmpty(data) || code !== "success") {
    return {
      data: null,
      connected: null,
      ...rest,
    }
  }

  return {
    data: data,
    connected: data.connected || data,
    ...rest,
  }
}
