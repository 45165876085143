import Config from "@environments/data"

export const getPrivateApiMePath = () => {
  return `${Config.private_api_url}/me`
}

export const getPrivateApiMeSubscriptionPath = () => {
  return `${getPrivateApiMePath()}/subscription`
}

export const getPrivateApiMeCapabilitiesPath = () => {
  return `${getPrivateApiMePath()}/capabilities`
}

export const getPrivateApiMeSearchTenantTokenPath = () => {
  return `${getPrivateApiMePath()}/search-tenant-token`
}

export const getPrivateApiMeCancelSubscriptionPath = () => {
  return `${getPrivateApiMePath()}/cancel-subscription`
}

export const getPrivateApiMeSendSlackNotificationContactSupportPath = () => {
  return `${getPrivateApiMePath()}/send-slack-notification-contact-support`
}

export const getPrivateApiMeRequestUpdateQuantityStripePath = () => {
  return `${getPrivateApiMePath()}/request-update-quantity-stripe`
}

export const getPrivateApiMeCountNotificationsPath = () => {
  return `${getPrivateApiMePath()}/total-notifications`
}

export const getPrivateApiMeDetailsPath = () => {
  return `${getPrivateApiMePath()}/details`
}
