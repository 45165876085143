import UserNotificationModel from "@/Core/Models/Notifications/UserNotificationModel"
import { isEmpty } from "lodash"
import BaseStrategyUserNotification from "../BaseStrategyUserNotification"
import { System } from "@design-system/index"

class UserNotificationProcessThemeUpdated extends BaseStrategyUserNotification {
  protected getBadge() {
    return {
      icon: System.Svg.Update,
      title: "Theme Update",
    }
  }

  public getMessageDataSuccess(notification: UserNotificationModel) {
    const { fails } = this.getTotalTasks(notification)

    let message = ""
    if (fails === 0) {
      message = `Successfully Completed. 👍`
    } else {
      message = `Completed. Your attention might be needed.`
    }

    return {
      message: message,
      badge: this.getBadge(),
      icons: this.getIcons(notification),
    }
  }

  public getMessageDataError(notification: UserNotificationModel) {
    const name = notification.getProjectName()

    let message = "The theme update task had an error"
    if (!isEmpty(name)) {
      message = `[${name}] - ${message}`
    }

    return {
      message: message,
      badge: this.getBadge(),
      icons: this.getIcons(notification),
    }
  }
}

export default UserNotificationProcessThemeUpdated
