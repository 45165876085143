import { isNil } from "lodash"
import UserNotificationModel from "../../Core/Models/Notifications/UserNotificationModel"
import StrategyUserNotification, {
  ResponseMessageData,
} from "./strategy/StrategyUserNotification"

class UserNotificationRender {
  protected strategy: StrategyUserNotification

  public setStrategy(strategy: StrategyUserNotification) {
    this.strategy = strategy
  }

  public getMessageData(
    notification: UserNotificationModel,
  ): ResponseMessageData | null {
    if (isNil(this.strategy)) {
      return null
    }
    return this.strategy.getMessageData(notification)
  }
}

export default UserNotificationRender
