import useSWR from "swr"
import fetcherPrivateApiUmbrella from "@helpers/fetcherPrivateApiUmbrella"
import { isEmpty, isNil } from "lodash"
import { getPrivateApiMeCapabilitiesPath } from "@/Profile/api/routes-private"
import { CapabilityType } from "@prisma/client"

interface Props {
  suspense?: boolean
  options?: {
    refreshInterval?: number
  }
  needMakeRequest?: boolean
}

export const useMeCapabilities = (
  { suspense, options, needMakeRequest = true }: Props = {
    suspense: true,
  },
) => {
  const { data: response, ...rest } = useSWR<{
    code: string
    data: CapabilityType[]
  }>(needMakeRequest ? getPrivateApiMeCapabilitiesPath() : null, {
    suspense: suspense,
    fetcher: fetcherPrivateApiUmbrella(),
    ...options,
  })

  const { code, data } = response || {}

  if (isNil(data) || isEmpty(data) || code !== "success") {
    return {
      data: null,
      connected: null,
      ...rest,
    }
  }

  return {
    data: data,
    ...rest,
  }
}
