import { useState } from "react"
import { get, isEmpty } from "lodash"
import useNotifications from "@hooks/notifications/useNotifications"
import { ProcessTasksType } from "@prisma/client"
import { useProcessesInProgressWithLastFinished } from "@components/Processes/hooks/useProcessesInProgressWithLastFinished"
import { getProjectsResyncPath } from "@/Project/api/routes-private"
import { getFetchPrivateHeaders } from "@/Core/Fetcher/getFetchPrivateHeaders"

const useResyncProjects = () => {
  const [loading, setLoading] = useState(false)
  const { handleNotifySuccess } = useNotifications()

  const { data: processes, mutate } = useProcessesInProgressWithLastFinished<{
    pending: Array<any>
    finished: Array<any>
  }>({
    types: [ProcessTasksType.SYNCHRONISE_PROJECT],
    sinceMs: 0,
    options: {
      refreshInterval: null,
    },
  })

  const handleResyncData = async (
    {
      projectIds,
    }: {
      projectIds?: number[]
    } = {
      projectIds: [],
    },
  ) => {
    if (!isEmpty(processes.pending)) {
      return
    }

    setLoading(true)

    const headers = await getFetchPrivateHeaders()

    await fetch(getProjectsResyncPath(), {
      method: "POST",
      headers: headers,
      body: JSON.stringify({
        projectIds,
      }),
    })

    handleNotifySuccess({
      title: "We have started the Re-Sync Data",
      message: <>You will receive a notification when this is completed.</>,
    })

    mutate()
    setLoading(false)
  }

  return {
    loading,
    processes: get(processes, "pending", []),
    handleResyncData,
  }
}

export default useResyncProjects
