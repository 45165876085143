import { isEmpty } from "lodash"

import UserNotificationModel from "../../../../Core/Models/Notifications/UserNotificationModel"
import BaseStrategyUserNotification from "../BaseStrategyUserNotification"
import { System } from "@design-system/index"

class UserNotificationProcessDatabaseOptimization extends BaseStrategyUserNotification {
  protected getBadge() {
    return {
      icon: System.Svg.Database,
      title: "Database Optimization",
    }
  }

  public getMessageDataSuccess(notification: UserNotificationModel) {
    const { fails } = this.getTotalTasks(notification)

    const name = notification.getProjectName()

    let message = ""
    if (fails === 0) {
      message = `Successfully Completed. 👍`
    } else {
      message = `Completed. Your attention might be needed.`
    }

    if (!isEmpty(name)) {
      message = `[${name}] - ${message}`
    }

    return {
      message: message,
      badge: this.getBadge(),
      icons: this.getIcons(notification),
    }
  }

  public getMessageDataError(notification: UserNotificationModel) {
    const message = "We couldn’t optimize your database."

    return {
      message: message,
      badge: this.getBadge(),
      icons: this.getIcons(notification),
    }
  }
}

export default UserNotificationProcessDatabaseOptimization
