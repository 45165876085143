import { getPluginFavicon } from "@/Helpers/getPluginFavicon"
import { getThemeFavicon } from "@/Helpers/getThemeFavicon"
import { NotificationStatus, UserNotificationType } from "@prisma/client"
import { get, indexOf, isEmpty, isNil } from "lodash"
import UserNotificationModel from "../../../Core/Models/Notifications/UserNotificationModel"
import { getFaviconProjectUrl } from "../../../Helpers/getFaviconProjectUrl"
import StrategyUserNotification, {
  ResponseMessageData,
} from "./StrategyUserNotification"

class BaseStrategyUserNotification implements StrategyUserNotification {
  getMessageDataSuccess(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    notification: UserNotificationModel,
  ): ResponseMessageData | null {
    return null
  }
  getMessageDataError(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    notification: UserNotificationModel,
  ): ResponseMessageData | null {
    return null
  }

  getIcons(notification: UserNotificationModel): {
    project?: string | null
    items?: string[]
  } {
    const project = notification.getProject()
    const icons = {
      project: null,
      items: [],
    } as {
      project?: string | null
      items?: string[]
    }

    switch (notification.type) {
      case UserNotificationType.BULK_PROCESS_ACTIVATE_PLUGIN_FINISHED:
      case UserNotificationType.BULK_PROCESS_DEACTIVATE_PLUGIN_FINISHED:
      case UserNotificationType.BULK_PROCESS_UPDATE_PLUGIN_FINISHED:
      case UserNotificationType.BULK_PROCESS_INSTALL_PLUGIN_FINISHED:
      case UserNotificationType.BULK_PROCESS_DELETE_PLUGIN_FINISHED:
      case UserNotificationType.PROCESS_UPDATE_PLUGIN_FINISHED:
      case UserNotificationType.PROCESS_ACTIVATE_PLUGIN_FINISHED:
      case UserNotificationType.PROCESS_DEACTIVATE_PLUGIN_FINISHED:
      case UserNotificationType.PROCESS_INSTALL_PLUGIN_FINISHED:
      case UserNotificationType.PROCESS_DELETE_PLUGIN_FINISHED:
        if (isEmpty(notification.tasks)) {
          return icons
        }

        for (const task of notification.tasks) {
          const slug = get(task, "entities.plugin", "").split("/")
          if (slug?.[0]) {
            const url = getPluginFavicon({
              slug: slug?.[0],
            })
            if (indexOf(icons.items, url) === -1) {
              icons.items?.push(url)
            }
          }
        }
        break
      case UserNotificationType.BULK_PROCESS_UPDATE_THEME_FINISHED:
      case UserNotificationType.PROCESS_UPDATE_THEME_FINISHED:
      case UserNotificationType.PROCESS_DELETE_THEME_FINISHED:
      case UserNotificationType.BULK_PROCESS_DELETE_THEME_FINISHED:
        if (isEmpty(notification.tasks)) {
          return icons
        }

        for (const task of notification.tasks) {
          const theme = get(task, "entities.theme", "")
          if (!isEmpty(theme)) {
            const url = getThemeFavicon({
              theme: theme,
              version: get(task, "entities.version", null),
            })

            if (indexOf(icons.items, url) === -1) {
              icons.items?.push(url)
            }
          }
        }
        break
      case UserNotificationType.BULK_PROCESS_UPDATE_CORE_FINISHED:
      case UserNotificationType.BACKUP_DOWNLOADABLE:
      case UserNotificationType.BACKUP_DOWNLOADABLE_DATABASE:
      case UserNotificationType.BACKUP_DOWNLOADABLE_FILE:
      case UserNotificationType.RESTORE_FINISHED:
      case UserNotificationType.SYNCHRONISE_PROJECT_FINISHED:
      case UserNotificationType.PROJECT_SCAN_VULNERABILITY_FINISHED:
        if (!isNil(project.base_url)) {
          icons.project = getFaviconProjectUrl({
            url: project.base_url,
          })
        }
        break
    }

    return icons
  }

  getTotalTasks(notification: UserNotificationModel) {
    const success = notification.tasksSuccess
    const fail = notification.tasksFails
    return {
      success: success,
      fails: fail,
      total: success + fail,
    }
  }

  getMessageData(
    notification: UserNotificationModel,
  ): ResponseMessageData | null {
    switch (notification.status) {
      case NotificationStatus.SUCCESS:
        return this.getMessageDataSuccess(notification)
      case NotificationStatus.ERROR:
        return this.getMessageDataError(notification)
      default:
        return null
    }
  }
}

export default BaseStrategyUserNotification
