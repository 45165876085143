import UserNotificationModel from "../../../Core/Models/Notifications/UserNotificationModel"
import BaseStrategyUserNotification from "./BaseStrategyUserNotification"

class DefaultUserNotification extends BaseStrategyUserNotification {
  public getMessageDataSuccess(notification: UserNotificationModel) {
    const { fails } = this.getTotalTasks(notification)

    let message = ""
    if (fails === 0) {
      message = `Successfully Completed. 👍`
    } else {
      message = `Completed. Your attention might be needed.`
    }

    return {
      message: message,
      icons: this.getIcons(notification),
    }
  }

  public getMessageDataError(notification: UserNotificationModel) {
    return {
      message: `An unknown error has occurred. Please contact support.`,
    }
  }
}

export default DefaultUserNotification
