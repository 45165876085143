import { get } from "lodash"
import { isCurrentTrial } from "../../helpers/user/isCurrentTrial"
import { useMeSubscription } from "@components/Profile/hooks/useMeSubscription"

interface Props {
  checkIsTrial?: boolean
}

const useUserIsPremium = ({ checkIsTrial }: Props = { checkIsTrial: true }) => {
  const { data } = useMeSubscription()

  if (get(data, "slug", "free") !== "free") {
    return true
  }

  return checkIsTrial
    ? isCurrentTrial({
        userCreatedAt: get(data, "User.created_at"),
      })
    : false
}

export default useUserIsPremium
