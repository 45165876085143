import React from "react"
import classNames from "classnames"
import { isNil } from "lodash"

interface Props {
  children: React.ReactNode
  blue?: boolean
  whiteBlue?: boolean
  green?: boolean
  red?: boolean
  yellow?: boolean
  gray?: boolean
  large?: boolean
  orange?: boolean
  className?: string
}

const Badge = ({
  children,
  blue,
  green,
  red,
  yellow,
  orange,
  gray,
  large,
  whiteBlue,
  className,
}: Props) => (
  <span
    className={classNames(
      {
        "bg-blue-100 text-blue-800": blue,
        "border border-blue-200 bg-white/50 text-blue-800": whiteBlue,
        "bg-green-100 text-green-800": green,
        "bg-red-100 text-red-800": red,
        "bg-yellow-100 text-yellow-800": yellow,
        "bg-orange-100 text-orange-800": orange,
        "bg-gray-100 text-gray-800": gray,
        "text-xs": isNil(large),
      },
      `inline-flex items-center rounded-full px-2 py-1 font-medium ${className}`,
    )}
  >
    {children}
  </span>
)

export default Badge
