import { System } from "@design-system/index"
import UserNotificationModel from "../../../../Core/Models/Notifications/UserNotificationModel"
import BaseStrategyUserNotification from "../BaseStrategyUserNotification"
import { ResponseMessageData } from "../StrategyUserNotification"

class UserNotificationBackupDownloadedDatabase extends BaseStrategyUserNotification {
  protected getBadge() {
    return {
      icon: System.Svg.Restore,
      title: "Restoration",
    }
  }

  public getMessageDataSuccess(
    notification: UserNotificationModel,
  ): ResponseMessageData {
    const name = notification.getProjectName()

    const message = `The restoration of [${name}] was successfully completed.`

    return {
      message: message,
      badge: this.getBadge(),
      icons: this.getIcons(notification),
    }
  }

  public getMessageDataError(notification: UserNotificationModel) {
    const name = notification.getProjectName()

    const message = `The restoration of [${name}] failed. Your attention might be needed`

    return {
      message: message,
      badge: this.getBadge(),
      icons: this.getIcons(notification),
      link: `/projects/${notification.getProjectId()}/backups`,
    }
  }
}

export default UserNotificationBackupDownloadedDatabase
