import { isEmpty } from "lodash"
import UserNotificationModel from "../../../../Core/Models/Notifications/UserNotificationModel"
import BaseStrategyUserNotification from "../BaseStrategyUserNotification"
import { System } from "@design-system/index"

class UserNotificationProcessPluginUpdated extends BaseStrategyUserNotification {
  protected getBadge() {
    return {
      icon: System.Svg.Update,
      title: "Plugin Update",
    }
  }

  public getMessageDataSuccess(notification: UserNotificationModel) {
    const { total, fails } = this.getTotalTasks(notification)

    let message = ""
    if (fails === 0) {
      message = `Successfully Completed. 👍`
    } else {
      message = `Completed. Your attention might be needed.`
    }

    const badge = this.getBadge()

    return {
      message: message,
      badge: {
        ...badge,
        title: total === 1 ? badge.title : `Plugins Update`,
      },
      icons: this.getIcons(notification),
    }
  }

  public getMessageDataError(notification: UserNotificationModel) {
    const name = notification.getProjectName()

    let message = "The plugin update task had an error"
    if (!isEmpty(name)) {
      message = `[${name}] - ${message}`
    }

    return {
      message: message,
      badge: this.getBadge(),
      icons: this.getIcons(notification),
    }
  }
}

export default UserNotificationProcessPluginUpdated
