import {
  NotificationStatus,
  UserNotification,
  UserNotificationType,
} from "@prisma/client"
import { formatDistance } from "date-fns"
import { get, isNil } from "lodash"
import { createSchema } from "morphism"

export interface SourceUserNotificationModel extends UserNotification {
  tasks?: Array<{ entities: any }>
  project?: {
    id: number
    base_url: string
    name: string
  }
}

export interface TargetUserNotificationModel extends UserNotification {
  tasks?: Array<{ entities: any }>
  project?: {
    id: number
    base_url: string
    name: string
  }
}

export const SchemaUserNotificationModel = createSchema<
  TargetUserNotificationModel,
  SourceUserNotificationModel
>({
  id: "id",
  read: "read",
  entities: "entities",
  project: "project",
  type: "type",
  status: "status",
  created_at: "created_at",
  deleted_at: "deleted_at",
  userId: "userId",
  tasks: "tasks",
})

class UserNotificationModel implements TargetUserNotificationModel {
  id: number
  read: boolean
  status: NotificationStatus
  type: UserNotificationType
  created_at: Date
  deleted_at: Date
  userId: number
  project?: { name: string; base_url: string; id: number }
  tasks: Array<{ entities: any }>
  entities: {
    tasks?: {
      success?: number
      fail?: number
    }
  }

  public get dateSince(): string {
    return formatDistance(new Date(this.created_at), new Date(), {
      addSuffix: true,
    })
  }

  public get tasksSuccess(): number {
    if (isNil(this.entities.tasks) || isNil(this.entities.tasks.success)) {
      return 0
    }
    return Number(this.entities.tasks.success)
  }

  public get tasksFails(): number {
    if (isNil(this.entities.tasks) || isNil(this.entities.tasks.fail)) {
      return 0
    }
    return Number(this.entities.tasks.fail)
  }

  public getProject(): { name: string; base_url: string; id: number } {
    return get(this, "project", { name: "", base_url: "", id: null })
  }

  public getProcessId() {
    return get(this, "entities.processId", null)
  }

  public getLinkDetail() {
    const processId = this.getProcessId()
    if (!isNil(processId)) {
      return `/processes/${processId}`
    }

    return null
  }

  public getProjectId() {
    return get(this, "project.id", null)
  }

  public getProjectName() {
    return get(this, "project.name", "")
  }
}

export default UserNotificationModel
