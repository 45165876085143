import React, { useState } from "react"
import classNames from "classnames"
import { indexOf, isEmpty, isNil } from "lodash"
import UserNotificationModel from "../../../../src/Core/Models/Notifications/UserNotificationModel"
import UserNotificationRender from "../../../../src/Services/UserNotifications/UserNotificationRender"
import { getStrategyByNotification } from "../../../../src/Services/UserNotifications/getStrategyByTypeNotification"
import Badge from "../../../../ui/Badge"
import {
  ArrowRightCircleIcon,
  EllipsisHorizontalCircleIcon,
} from "@heroicons/react/24/outline"
import { ResponseMessageData } from "../../../../src/Services/UserNotifications/strategy/StrategyUserNotification"
import { SVGChip } from "../../../../svg/Chip"
import System from "@ui/System"

const userNotificationRender = new UserNotificationRender()

interface Props {
  notification: UserNotificationModel
}

const Container = ({
  data,
  notification,
  children,
}: {
  data: ResponseMessageData
  notification: UserNotificationModel
  children: React.ReactNode
}) => {
  let linkDetail = notification.getLinkDetail()
  if (!isNil(data.link) && !isEmpty(data.link)) {
    linkDetail = data.link
  }

  if (!isNil(data.onClick)) {
    return (
      <div
        className={classNames({
          "opacity-[40%]": notification.read,
        })}
        onClick={data.onClick}
      >
        {children}
      </div>
    )
  }

  if (isNil(linkDetail)) {
    return (
      <div
        className={classNames({
          "opacity-[40%]": notification.read,
        })}
      >
        {children}
      </div>
    )
  }

  return (
    <System.Link
      href={linkDetail}
      passHref
      className={classNames({
        "opacity-[40%]": notification.read,
      })}
    >
      {children}
    </System.Link>
  )
}

const Item = ({ notification }: Props) => {
  const [errorImage, setErrorImage] = useState([])

  userNotificationRender.setStrategy(getStrategyByNotification(notification))

  const data = userNotificationRender.getMessageData(notification)

  if (isNil(data)) {
    return null
  }

  const Icon = isNil(data?.badge?.icon) ? null : data.badge.icon

  return (
    <li
      className={classNames(
        "relative mb-2 cursor-pointer rounded-lg border bg-white px-4 py-5 transition hover:bg-indigo-50",
      )}
    >
      <Container data={data} notification={notification}>
        <>
          <div className="mb-2 flex items-center gap-4">
            {!isEmpty(data?.icons?.project) && (
              <img
                className="h-6 w-6 rounded-full"
                src={data?.icons?.project}
              />
            )}
            {!isEmpty(data?.badge?.title) && (
              <Badge whiteBlue className="gap-2">
                {data?.badge?.icon && <Icon className="h-4 w-4" />}
                {data?.badge?.title}
              </Badge>
            )}
            {!isEmpty(data?.icons?.items) && (
              <div className="isolate flex -space-x-1 overflow-hidden">
                {data?.icons?.items.map((item, index) => {
                  if (indexOf(errorImage, index) === -1) {
                    return (
                      <img
                        key={`icon-${index}`}
                        className="relative z-30 inline-block h-6 w-6 rounded-full ring-2 ring-white"
                        src={item}
                        alt=""
                        onError={() => {
                          setErrorImage((prev) => [...prev, index])
                        }}
                      />
                    )
                  }

                  return (
                    <div
                      className="relative z-30 flex h-6 w-6 items-center justify-center rounded-full ring-2 ring-white"
                      key={`icon-${index}`}
                    >
                      <SVGChip />
                    </div>
                  )
                })}
                {data?.icons?.items.length === 4 && (
                  <EllipsisHorizontalCircleIcon className="relative z-0 inline-block h-6 w-6 rounded-full" />
                )}
              </div>
            )}
            <time className="ml-auto whitespace-nowrap text-sm text-gray-500">
              {notification.dateSince}
            </time>
          </div>
          <System.Text as={"div"}>
            <div className="flex items-center gap-2">
              {data.message}
              <ArrowRightCircleIcon className="ml-auto h-5 w-5" />
            </div>
          </System.Text>
        </>
      </Container>
    </li>
  )
}

export default Item
