import useSWR from "swr"
import qs from "query-string"
import fetcherPrivateApiUmbrella from "@helpers/fetcherPrivateApiUmbrella"
import { isEmpty, isNil } from "lodash"
import { getPrivateApiMeCountNotificationsPath } from "@/Profile/api/routes-private"

type Data = {
  total: number
}

interface Props {
  suspense?: boolean
  options?: {
    refreshInterval?: number
  }
}

export const useMeCountNotificationsNotRead = (
  { suspense, options }: Props = {
    suspense: true,
  },
) => {
  const path = qs.stringifyUrl({
    url: getPrivateApiMeCountNotificationsPath(),
    query: {
      read: 0,
    },
  })

  const { data: response, ...rest } = useSWR<{
    code: string
    data: Data
  }>(path, {
    suspense: suspense,
    fetcher: fetcherPrivateApiUmbrella(),
    ...options,
  })

  const { code, data } = response || {}

  if (isNil(data) || isEmpty(data) || code !== "success") {
    return {
      data: null,
      ...rest,
    }
  }

  return {
    data: data,
    ...rest,
  }
}
