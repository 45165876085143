import { User } from "@/Types/User/User"
import { System } from "@design-system/index"
import { getDiffDayCreatedAtUser } from "@helpers/user/getDiffDayCreatedAtUser"
import { TRIAL_DAYS, isCurrentTrial } from "@helpers/user/isCurrentTrial"
import { ChevronRightIcon } from "@heroicons/react/24/outline"
import useUser from "@hooks/user/useUser"
import useUserIsPremium from "@hooks/user/useUserIsPremium"
import classNames from "classnames"
import { get, isNil } from "lodash"

export const TrialBar = () => {
  const { mainUser: user, isAdminLogInAs } = useUser<User>({
    options: {
      withModel: true,
    },
  })

  const createdAt = get(user, "created_at")

  const isPremiumUser = useUserIsPremium({ checkIsTrial: false })

  if (isNil(createdAt)) {
    return null
  }

  // Is trial compare only created_at + TRIAL_DAYS
  const isTrial = isCurrentTrial({
    userCreatedAt: String(createdAt),
  })

  if (!isTrial) {
    return null
  }

  if (isPremiumUser) {
    return null
  }

  const diffDayCreatedAt = getDiffDayCreatedAtUser({
    userCreatedAt: String(createdAt),
  })
  const total = TRIAL_DAYS - diffDayCreatedAt

  return (
    <div
      className={classNames(
        "fixed h-14 w-full bg-gradient-to-r from-indigo-600 via-blue-700 to-indigo-600",
        {
          "top-0": isNil(isAdminLogInAs),
          "top-14": !isNil(isAdminLogInAs),
        },
      )}
      style={{
        zIndex: 1500,
      }}
    >
      <div className="flex h-full items-center justify-center gap-2 font-semibold text-white">
        You have {total} day
        {total > 1 && "s"} of free trial left 😱
        <svg width="2" height="2" aria-hidden="true" className="fill-white">
          <circle cx="1" cy="1" r="1"></circle>
        </svg>
        <span className="flex items-center gap-2">
          <System.Link
            className="border-b border-white/50"
            naked
            href="/profile/subscription"
          >
            Upgrade now
          </System.Link>
          <ChevronRightIcon className="h-4 w-4" />
        </span>
      </div>
    </div>
  )
}
